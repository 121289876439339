import { css } from '@emotion/react';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import {
  Box,
  Button,
  desktopFirstMediaQuery,
  Icon,
  type MantineTheme,
  Menu,
  Text,
  useTheme
} from '@inflearn/ds-react';
import { isEmpty } from 'lodash-es';
import { useLocation } from '../../../../layouts/Infcon2024Layout';
import { prefix, URLS } from '../../../../utils/urls';
import { useMainButtonText } from './useMainButtonText';

const Header = () => {
  const theme = useTheme();
  const dropdownItemStyle = getHeaderDropdownItemStyle(theme);
  const { buttonText, isShowButton } = useMainButtonText();
  const location = useLocation();
  const getStyleWhenPathnameIncluded = (url: string | string[]) => {
    if (location == null) {
      return;
    }
    const style = styleActiveButton(theme);
    if (typeof url === 'object') {
      const filteredUrls = url.filter((urlItem) => location.pathname.includes(urlItem));
      if (isEmpty(filteredUrls)) {
        return false;
      }
      return style;
    }

    return location.pathname.includes(url) && style;
  };

  return (
    <Box component="header" css={styleHeader(theme)} id={'infcon-2023-header-pc'}>
      <Box
        css={{
          width: '100%',
          maxWidth: 1200
        }}>
        <Box css={styleHeaderBody(theme)}>
          <a href={`${prefix}${URLS.INFCON_2024__HOME}`}>
            <Text size="lg" weight="bold" color="black">
              INFCON 2024
            </Text>
          </a>
          <Box
            component="ul"
            css={{
              display: 'flex',
              listStyle: 'none'
            }}>
            <Box component="li">
              <a href={`${prefix}${URLS.INFCON_2024__SESSION}`}>
                <Button
                  variant="subtle"
                  color="dark"
                  size="md"
                  radius="md"
                  compact
                  css={[
                    headerButtonStyle,
                    getStyleWhenPathnameIncluded(URLS.INFCON_2024__SESSION)
                  ]}>
                  발표/프로그램
                </Button>
              </a>
            </Box>
            <Box component="li">
              <Menu withArrow styles={{ item: { padding: 0 } }} trigger="hover">
                <Menu.Target>
                  <a href={`${prefix}${URLS.INFCON_2024__EVENT}`}>
                    <Button
                      variant="subtle"
                      color="dark"
                      size="md"
                      radius="md"
                      compact
                      css={[
                        headerButtonStyle,
                        getStyleWhenPathnameIncluded([URLS.INFCON_2024__EVENT, URLS.INFCON__ACTION])
                      ]}
                      rightIcon={<Icon icon={faAngleDown} />}>
                      행사 즐기기
                    </Button>
                  </a>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item>
                    <a
                      href={`${prefix}${URLS.INFCON_2024__EVENT}`}
                      css={[
                        dropdownItemStyle,
                        getStyleWhenPathnameIncluded(URLS.INFCON_2024__EVENT)
                      ]}>
                      이벤트
                    </a>
                  </Menu.Item>
                  <Menu.Item css={{ width: '130px' }}>
                    <Box
                      component="a"
                      target={'_blank'}
                      href={URLS.INFCON_2024__ACTION}
                      css={[dropdownItemStyle, getStyleWhenPathnameIncluded(URLS.INFCON__ACTION)]}>
                      이것만은 지켜요
                    </Box>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Box>
            <Box component="li">
              <a href={`${prefix}${URLS.INFCON_2024__FAQ}`}>
                <Button
                  variant="subtle"
                  color="dark"
                  size="md"
                  radius="md"
                  compact
                  css={[headerButtonStyle, getStyleWhenPathnameIncluded(URLS.INFCON_2024__FAQ)]}>
                  FAQ
                </Button>
              </a>
            </Box>
            <Box component="li">
              <a href={`${prefix}${URLS.INFCON_2024__MY}`}>
                <Button
                  variant="subtle"
                  color="dark"
                  size="md"
                  radius="md"
                  compact
                  css={[headerButtonStyle, getStyleWhenPathnameIncluded(URLS.INFCON_2024__MY)]}>
                  MY
                </Button>
              </a>
            </Box>
          </Box>
          {isShowButton ? (
            <Button
              size="sm"
              component="a"
              href={URLS.INFCON_2024__APPLY}
              target="_blank"
              radius="sm"
              variant="default"
              color="dark"
              css={{
                transition: 'background 0.2s ease'
              }}
              leftIcon={<Icon icon={faArrowUpRightFromSquare} />}
              disabled={isShowButton}>
              {buttonText}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const headerButtonStyle = css({
  padding: '0 16px',
  '&:hover': {
    backgroundColor: 'transparent !important'
  }
});

const getHeaderDropdownItemStyle = (theme: MantineTheme) =>
  css({
    display: 'block',
    padding: '10px 12px',
    width: '130px',
    height: '100%',
    color: theme.colors.dark[9],
    fontSize: '16px',
    ':hover': {
      backgroundColor: theme.colors.gray[1]
    }
  });

const styleHeader = (theme: MantineTheme) =>
  css({
    zIndex: 30,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    width: '100%',
    height: '64px',
    backgroundColor: 'inherit',
    backdropFilter: 'blur(20px)',
    [desktopFirstMediaQuery.mobile]: {
      display: 'none'
    }
  });
const styleHeaderBody = (theme: MantineTheme) =>
  css({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '0 32px',
    maxWidth: 1200,
    color: theme.colors.gray[7]
  });

const styleActiveButton = (theme: MantineTheme) =>
  css({
    color: theme.colors.blue[7]
  });

export default Header;
